import {
  BrowserRouter,
  Form,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import SignIn from "./Pages/SignIn";
import Dashboard from "./Pages/Dashboard";
import { useEffect } from "react";
import FetchData from "./Fetch/FetchData";
import UserData from "./Fetch/UserData";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import SignUp from "./Pages/Signup";
// import SignUp from "./Pages/Signup"
function App() {
  useEffect(() => {
    const unsubscribe = FetchData();

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = UserData();

    return () => unsubscribe();
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer></ToastContainer>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/Signup" element={<SignUp />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
