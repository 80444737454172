import React, { useState } from "react";
import {
  Add,
  ChatRounded,
  Devices,
  Home,
  Logout,
  NotificationAdd,
  Person,
  PieChartOutline,
  Texture,
} from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { setGlobalState, useGlobalState } from "../../GlobalState/Globalstate";

export default function Sidebar() {
  const showSidebar = useGlobalState("showSideBar");

  return (
    <div
      className="p-5 shadow-xl fixed  gap-4 md:w-[250px] lg:w-[300px] h-full bg-white"
      style={{ zIndex: 100 }}
    >
      <div
        className="flex sm:hidden"
        onClick={() => setGlobalState("showSideBar", !showSidebar[0])}
      >
        <HighlightOffOutlinedIcon />
      </div>
      <Box
        className="mt-5 p-5 pl-0 cursor-pointer"
        onClick={() => {
          setGlobalState("showDevices", false);
          setGlobalState("showNotification", false);
          setGlobalState("showMain", true);
          setGlobalState("showProfile", false);
        }}
      >
        <div className="flex gap-2">
          <Home></Home> <h1>Home</h1>
        </div>
      </Box>
      <Divider></Divider>
      <Box
        className="mt-5 p-5 pl-0 cursor-pointer"
        onClick={() => {
          setGlobalState("showDevices", true);
          setGlobalState("showNotification", false);
          setGlobalState("showMain", false);
          setGlobalState("showProfile", false);
        }}
      >
        <div className="flex gap-2">
          <Devices /> <h1>Devices</h1>
        </div>
      </Box>
      <Divider></Divider>
      <Box
        className="mt-5 p-5 pl-0 cursor-pointer"
        onClick={() => {
          setGlobalState("addDevice", true);
        }}
      >
        <div className="flex gap-2">
          <Add /> <h1>Add Devices</h1>
        </div>
      </Box>

      <Divider></Divider>
      <Box
        className="mt-5 p-5 pl-0 cursor-pointer"
        onClick={() => {
          setGlobalState("showDevices", false);
          setGlobalState("showMain", false);
          setGlobalState("showNotification", true);
          setGlobalState("showProfile", false);
        }}
      >
        <div className="flex gap-2">
          <NotificationAdd /> <h1>Notifications</h1>
        </div>
      </Box>
      <Divider></Divider>
      <Box
        className="mt-5 p-5 pl-0 cursor-pointer"
        onClick={() => {
          setGlobalState("showDevices", false);
          setGlobalState("showMain", false);
          setGlobalState("showNotification", false);
          setGlobalState("showProfile", true);
        }}
      >
        <div className="flex gap-2">
          <Person /> <h1>Profile</h1>
        </div>
      </Box>
      <Divider></Divider>
      <Box
        className="mt-5 p-5 pl-0 cursor-pointer"
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
      >
        <div className="flex gap-2">
          <Logout /> <h1>Logout</h1>
        </div>
      </Box>
    </div>
  );
}
