import {
  Air,
  Close,
  DeviceThermostat,
  FireplaceTwoTone,
  GasMeter,
  GasMeterTwoTone,
  Height,
  Light,
  LineWeight,
  LinkedCamera,
  SmokeFree,
  TenMp,
  Water,
  WaterDrop,
} from "@mui/icons-material";
import { Box, Divider, IconButton, TextField } from "@mui/material";
import { initializeApp } from "firebase/app";
import React, { useState } from "react";
import { firebaseConfig } from "../../Firebase/firebaseconfig";
import { getDatabase, push, ref, update } from "firebase/database";
import { setGlobalState, useGlobalState } from "../../GlobalState/Globalstate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { ClockIcon } from "@mui/x-date-pickers";

export default function DeviceInfo() {
  const app = initializeApp(firebaseConfig);
  const deviceid = useGlobalState("deviceInfoId");
  const db = getDatabase(app);
  const Alldevices = useGlobalState("devices");
  const [param, setParam] = useState(
    Alldevices[0][deviceid[0]].params !== undefined
      ? Alldevices[0][deviceid[0]].params
      : undefined
  );
  try {
    return (
      <div className="absolute w-full overflow-auto sm:w-full h-full backdrop-blur-md top-0 z-[2] left-0 grid justify-center items-center">
        <div className="bg-white p-5 rounded shadow-lg">
          <div className="flex w-full">
            <div className="w-[90%]">
              <h1 className="w-full text-[30px]">Device Info ({deviceid[0]})</h1>
            </div>
            <IconButton
              onClick={() => {
                setGlobalState("deviceInfo", false);
              }}
            >
              <Close sx={{ color: "red" }}></Close>
            </IconButton>
          </div>
          <Divider></Divider>
          {}
          {param !== undefined ? (
            <>
              <div className="p-5 flex gap-5 w-[300px] overflow-auto sm:w-full">
                <Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                      <TableHead>
                        {param.map((param) => {
                          return param.show ? (
                            <TableRow>
                              <TableCell> {param.field} </TableCell>

                              <TableCell align="right">
                               
                                {Alldevices[0][deviceid[0]][param.value]}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <></>
                          );
                        })}
                        <TableRow>
                          <TableCell>
                            {" "}
                            <ClockIcon></ClockIcon> Last Updated Time{" "}
                          </TableCell>

                          <TableCell align="right">
                            {Alldevices[0][deviceid[0]].CurrentDate}{" "}
                            {Alldevices[0][deviceid[0]].CurrentTIme}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Box>
              </div>
            </>
          ) : (
            <div className="p-5 flex gap-5 w-[300px] overflow-auto sm:w-full">
              <Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 220 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {" "}
                          sensor1

                        </TableCell>
                        <TableCell align="right">
                          {Alldevices[0][deviceid[0]].sensor1value
}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {" "}
                          sensor2
                        </TableCell>

                        <TableCell align="right">
                          {Alldevices[0][deviceid[0]].sensor2value}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {" "}
                          <GasMeter></GasMeter> sensor3
                        </TableCell>

                        <TableCell align="right">
                          {Alldevices[0][deviceid[0]].sensor3value}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {" "}
                          <Water></Water> sensor4
                        </TableCell>

                        <TableCell align="right">
                          {Alldevices[0][deviceid[0]].sensor4value}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {" "}
                          <Light></Light> sensor5
                        </TableCell>

                        <TableCell align="right">
                          {Alldevices[0][deviceid[0]].sensor5value}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {" "}
                          <GasMeterTwoTone></GasMeterTwoTone> sensor6
                        </TableCell>

                        <TableCell align="right">
                          {Alldevices[0][deviceid[0]].sensor6value}
                        </TableCell>
                      </TableRow>
               
                      <TableRow>
                        <TableCell>
                          {" "}
                          <ClockIcon></ClockIcon> Last Updated Time{" "}
                        </TableCell>

                        <TableCell align="right">
                          {Alldevices[0][deviceid[0]].CurrentDate}{" "}
                          {Alldevices[0][deviceid[0]].CurrentTIme}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody></TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>
          )}
        </div>
      </div>
    );
  } catch (err) {
    toast.error("Error! Device Doesnt exist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
}
