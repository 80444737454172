import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { setGlobalState, useGlobalState } from "../../../GlobalState/Globalstate";
import { child, getDatabase, ref, remove } from "firebase/database";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../Firebase/firebaseconfig";
import { toast } from "react-toastify";
import FetchData from "../../../Fetch/FetchData";
import UserData from "../../../Fetch/UserData";

export default function DashInvalidDevices() {
  const app = initializeApp(firebaseConfig);
  const Alldevices = useGlobalState("devices")
  const dbRef = ref(getDatabase(app));
  const devices = useGlobalState("user")[0].devices;

  const deleteDevice = (deviceid) => {
    for (const key in devices) {
      if (devices[key] === deviceid) {
        remove(child(dbRef, `users/${localStorage.uid}/devices/${key}`)).then(
          () => {
            toast.success("Device Deleted successfully");
            FetchData();
            UserData();
          }
        );
      }
    }
  };
  const inActiveDevicesId = useGlobalState("inActiveDevicesId");
  function createData(
    DeviceId: String,
    DeviceName: String,
    Customer: String,
    StartTime: String,
    EndTime: String,
    Status: String,
    option: String
  ) {
    return {
      DeviceId,
      DeviceName,
      Customer,
      StartTime,
      EndTime,
      Status,
      option,
    };
  }
  let rows = [];
  inActiveDevicesId[0].map((item) => {
    rows.push(
      createData(
        item,
        Alldevices[0][item].DeviceName !== undefined
        ? Alldevices[0][item].DeviceName
        : "-",
        localStorage.userName,
   
      Alldevices[0][item].StartTime !== undefined
        ? Alldevices[0][item].StartTime
        : "-",
        Alldevices[0][item].EndTime ? Alldevices[0][item].EndTime : "-",
        "InActive",
        "View"
      )
    );
  });
  return (
    <div className="p-5 pl-0">
      <h1 className="text-[20px] font-[600] mb-5 m-5 ml-0">InActive Devices</h1>
      <TableContainer className="shadow-md">
        <Table sx={{ minWidth: 50 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Device ID</TableCell>
              <TableCell align="center">Device</TableCell>
              <TableCell align="center">Customer</TableCell>
              <TableCell align="center">Start Time</TableCell>
              <TableCell align="center">End Time</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Option</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.DeviceId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {row.DeviceId}
                </TableCell>
                <TableCell align="center">{row.DeviceName}</TableCell>
                <TableCell align="center">{row.Customer}</TableCell>
                <TableCell align="center">{row.StartTime}</TableCell>
                <TableCell align="center">{row.EndTime}</TableCell>
                <TableCell align="center">{row.Status}</TableCell>
                <TableCell align="center">
                <button
                        className="px-2 py-1 text-white bg-green-500 rounded"
                        onClick={() => {
                          setGlobalState("deviceOptions", true);
                          setGlobalState("deviceStatus", "InActive");
                          setGlobalState("deviceInfoId", row.DeviceId);
                        }}
                      >
                        {row.option}
                      </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
