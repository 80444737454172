import React, { useEffect } from "react";

import Devices from "./Devices";
import { Avatar } from "@mui/material";
import { setGlobalState, useGlobalState } from "../../GlobalState/Globalstate";
import AddDevices from "./AddDevices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import DashHeader from "./HomeComponents/DashHeader";
import DashDevices from "./HomeComponents/DashDevices";
import DashNotification from "./HomeComponents/DashNotification";
import DashDevicePie from "./HomeComponents/DashDevicePie";
import DashDeviceChart from "./HomeComponents/DashDeviceChart";
import DashInvalidDevices from "./HomeComponents/DashInvalidDevices";
import DashNewDevices from "./HomeComponents/DashNewDevices";
import DeviceInfo from "./DeviceInfo";
import DeviceOptions from "./DeviceOptions";

export default function Main() {
  
  const addDevice = useGlobalState("addDevice");
  const deviceinfo = useGlobalState("deviceInfo");
  const deviceOptions = useGlobalState("deviceOptions");
  const userInfo=useGlobalState("user")
  const Alldevices=useGlobalState("devices")[0]
  let heartBeat=[]
  let userDevices;
  try {
    userDevices = Object.values(userInfo[0].devices);
    userDevices.map((item) => {
      heartBeat.push({
        deviceId:item,
        lastUpdated:Alldevices[item].CurrentTime,
        heartBeat: Alldevices[item].heartBeat,
        lastUpdatedDate: Alldevices[item].CurrentDate,
        DeviceName: Alldevices[item].DeviceName
      })
      //console.log(heartBeat)
    })



  } catch (err) {
    userDevices = [];
  }
  setGlobalState("heartBeat",heartBeat)
  return (
    <div className="w-full md:w-[80%] p-5">
      {deviceinfo[0] ? <DeviceInfo></DeviceInfo> : <></>}
      {deviceOptions[0] ? <DeviceOptions></DeviceOptions> : <></>}
      <DashHeader></DashHeader>
      <div className="md:flex">
        <DashDevices></DashDevices>
        <DashNotification></DashNotification>
      </div>
      <div className="">
        <DashInvalidDevices></DashInvalidDevices>
      </div>
      <div className="md:flex">
        <DashDevicePie></DashDevicePie>
        <DashDeviceChart></DashDeviceChart>
      </div>
    </div>
  );
}
