import {
  ChatRounded,
  Home,
  Logout,
  NotificationAdd,
  Person,
  PieChartOutline,
} from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React, { useEffect } from "react";
import Sidebar from "./Components/Sidebar";
import Main from "./Components/Main";
import Devices from "./Components/Devices";
import NotficationsPage from "./Components/NotficationsPage";
import { useGlobalState } from "../GlobalState/Globalstate";
import AddDevices from "./Components/AddDevices";
import { useNavigate } from "react-router-dom";
import Profile from "./Components/Profile";
import DashHeader from "./Components/HomeComponents/DashHeader";
import HeartBeatError from "./HeartBeatError";

export default function Dashboard() {
  useEffect(()=>{

  },[])
  const showDevices = useGlobalState("showDevices");
  const showMain = useGlobalState("showMain");
  const addDevice = useGlobalState("addDevice");
  const showNotification = useGlobalState("showNotification");
  const showProfile = useGlobalState("showProfile");
  const showSideBar = useGlobalState("showSideBar");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.uid === undefined) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="w-full h-full flex-column sm:flex">
      <HeartBeatError></HeartBeatError>
      <div
        className={`h-full w-full ${
          !showSideBar[0] && !showMain[0] ? "block" : "hidden"
        } flex sm:hidden `}
        style={{ zIndex: 1999 }}
      >
        <DashHeader />
      </div>

      <div
        className={`h-full w-full ${
          showSideBar[0] ? "block" : "hidden"
        } flex  sm:relative md:w-[350px] sm:w-[200px] sm:block`}
        style={{ zIndex: 1999 }}
      >
        <Sidebar></Sidebar>
      </div>
      {addDevice[0] ? <AddDevices></AddDevices> : <></>}
      {showMain[0] ? <Main></Main> : <></>}
      {showDevices[0] ? <Devices></Devices> : <></>}
      {showNotification[0] ? <NotficationsPage></NotficationsPage> : <></>}
      {showProfile[0] ? <Profile></Profile> : <></>}
    </div>
  );
}
