import React, { useState } from "react";
import {
  setGlobalState,
  useGlobalState,
} from "../../../GlobalState/Globalstate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../Firebase/firebaseconfig";
import { child, getDatabase, ref, remove } from "firebase/database";
import FetchData from "../../../Fetch/FetchData";
import UserData from "../../../Fetch/UserData";
import { toast } from "react-toastify";
export default function DeviceList() {
  const app = initializeApp(firebaseConfig);

  const dbRef = ref(getDatabase(app));
  const devices = useGlobalState("user")[0].devices;
  const deleteDevice = (deviceid) => {
    for (const key in devices) {
      if (devices[key] === deviceid) {
        remove(child(dbRef, `users/${localStorage.uid}/devices/${key}`)).then(
          () => {
            toast.success("Device Deleted successfully");
            FetchData();
            UserData();
          }
        );
      }
    }
  };
  const Alldevices = useGlobalState("devices");
  const userInfo = useGlobalState("user");
  const InActiveDevices=useGlobalState("inActiveDevicesId")[0]
  const deviceinfo = useGlobalState("deviceInfo");
  const [All, setAll] = useState(true);
  const [Active, setActive] = useState(false);
  const [InActive, setInActive] = useState(false);
  let userDevices;
  try {
    userDevices = Object.values(userInfo[0].devices);
  } catch (err) {
    userDevices = [];
  }
  function createData(
    DeviceId: String,
    DeviceName: String,
    Customer: String,
    TotalLogs: int,
    StartTime: String,
    EndTime: String,
    Status: String,
    option: String
  ) {
    return {
      DeviceId,
      DeviceName,
      Customer,
      TotalLogs,
      StartTime,
      EndTime,
      Status,
      option,
    };
  }
  let rows = [];
  let active = [];
  let inactive = [];

  userDevices.map((item) => {
    if (Alldevices[0][item] !== undefined) {
      //console.log(Alldevices[0][item]);
      //console.log()
      rows.push(
        createData(
          item,
          Alldevices[0][item].DeviceName !== undefined
            ? Alldevices[0][item].DeviceName
            : "-",
          localStorage.userName,
          Alldevices[0][item].TotalLogs !== undefined
            ? Alldevices[0][item].TotalLogs
            : "-",
          Alldevices[0][item].StartTime !== undefined
            ? Alldevices[0][item].StartTime
            : "-",
          Alldevices[0][item].EndTime ? Alldevices[0][item].EndTime : "-",
         (InActiveDevices.includes(item))?"InActive":"Active",
          "View"
        )
      );
    } else {
      rows.push(
        createData(
          item,
          "-",
          localStorage.userName,
          "-",
          "-",
          "-",
          "Device Not Found",
          "Delete"
        )
      );
    }
  });
  userDevices.map((item) => {
    if (!InActiveDevices.includes(item)) {
      //console.log(Alldevices[0][item]);

      active.push(
        createData(
          item,
          Alldevices[0][item].DeviceName !== undefined
            ? Alldevices[0][item].DeviceName
            : "-",
          localStorage.userName,
          Alldevices[0][item].TotalLogs !== undefined
            ? Alldevices[0][item].TotalLogs
            : "-",
          Alldevices[0][item].StartTime !== undefined
            ? Alldevices[0][item].StartTime
            : "-",
          Alldevices[0][item].EndTime ? Alldevices[0][item].EndTime : "-",
          Alldevices[0][item].TurnedOn ? "Active" : "Turned Off",
          "View"
        )
      );
    }
  });
  userDevices.map((item) => {
    if (InActiveDevices.includes(item)) {
      //console.log(Alldevices[0][item]);

      inactive.push(
        createData(
          item,
          Alldevices[0][item].DeviceName !== undefined
            ? Alldevices[0][item].DeviceName
            : "-",
          localStorage.userName,
          Alldevices[0][item].TotalLogs !== undefined
            ? Alldevices[0][item].TotalLogs
            : "-",
          Alldevices[0][item].StartTime !== undefined
            ? Alldevices[0][item].StartTime
            : "-",
          Alldevices[0][item].EndTime ? Alldevices[0][item].EndTime : "-",
          (InActiveDevices[item]!=="undefined")?"InActive":"Active",
  
          "View"
        )
      );
    }
  });

  return (
    <div className="">
      <div className="flex gap-5 p-5 pl-5 bg-[#F4F4F4] ">
        <div>
          {All ? (
            <button className="px-2 py-2 rounded-full bg-white shadow-md">
              All Devices
            </button>
          ) : (
            <button
              className="px-2 py-2 rounded-full text-gray-400 bg-white shadow-sm"
              onClick={() => {
                setAll(true);
                setActive(false);
                setInActive(false);
              }}
            >
              All Devices
            </button>
          )}
        </div>
        <div>
          {Active ? (
            <button className="px-2 py-2 rounded-full bg-white shadow-md">
              Active Devices{" "}
            </button>
          ) : (
            <button
              className="px-2 py-2 rounded-full text-gray-400 bg-white shadow-sm"
              onClick={() => {
                setAll(false);
                setActive(true);
                setInActive(false);
              }}
            >
              Active Devices
            </button>
          )}
        </div>
        <div>
          {InActive ? (
            <button className="px-2 py-2 rounded-full bg-white shadow-md">
              InActive Devices
            </button>
          ) : (
            <button
              className="px-2 py-2 rounded-full text-gray-400 bg-white shadow-sm"
              onClick={() => {
                setAll(false);
                setActive(false);
                setInActive(true);
              }}
            >
              InActive Devices
            </button>
          )}
        </div>
      </div>
      {All ? (
        <TableContainer className="shadow-md">
          <Table sx={{ minWidth: 50 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Device ID</TableCell>
                <TableCell align="center">Device Name</TableCell>

                <TableCell align="center">Customer</TableCell>
                <TableCell align="center">Total Logs</TableCell>
                <TableCell align="center">Start Time</TableCell>
                <TableCell align="center">End Time</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Option</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.DeviceId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row.DeviceId}
                  </TableCell>
                  <TableCell align="center">{row.DeviceName}</TableCell>
                  <TableCell align="center">{row.Customer}</TableCell>
                  <TableCell align="center">{row.TotalLogs}</TableCell>
                  <TableCell align="center">{row.StartTime}</TableCell>
                  <TableCell align="center">{row.EndTime}</TableCell>
                  <TableCell align="center">{row.Status}</TableCell>
                  <TableCell align="center">
                    {row.option === "View" ? (
                      <button
                        className="px-2 py-1 text-white bg-green-500 rounded"
                        onClick={() => {
                          setGlobalState("deviceOptions", true);
                          setGlobalState("deviceStatus", row.Status);
                          setGlobalState("deviceInfoId", row.DeviceId);
                        }}
                      >
                        {row.option}
                      </button>
                    ) : (
                      <button
                        className="px-2 py-1 text-white bg-red-500 rounded"
                        onClick={() => {
                          deleteDevice(row.DeviceId);
                        }}
                      >
                        {row.option}
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      {Active ? (
        <TableContainer className="shadow-md">
          <Table sx={{ minWidth: 50 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Device ID</TableCell>
                <TableCell align="center">Device</TableCell>
                <TableCell align="center">Customer</TableCell>
                <TableCell align="center">Total Logs</TableCell>
                <TableCell align="center">Start Time</TableCell>
                <TableCell align="center">End Time</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Option</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {active.map((row) => (
                <TableRow
                  key={row.DeviceId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row.DeviceId}
                  </TableCell>
                  <TableCell align="center">{row.DeviceName}</TableCell>
                  <TableCell align="center">{row.Customer}</TableCell>
                  <TableCell align="center">{row.TotalLogs}</TableCell>
                  <TableCell align="center">{row.StartTime}</TableCell>
                  <TableCell align="center">{row.EndTime}</TableCell>
                  <TableCell align="center">{row.Status}</TableCell>
                  <TableCell align="center">
                    {row.option === "View" ? (
                      <button
                        className="px-2 py-1 text-white bg-green-500 rounded"
                        onClick={() => {
                          setGlobalState("deviceOptions", true);
                          setGlobalState("deviceStatus", row.Status);
                          setGlobalState("deviceInfoId", row.DeviceId);
                        }}
                      >
                        {row.option}
                      </button>
                    ) : (
                      <button
                        className="px-2 py-1 text-white bg-red-500 rounded"
                        onClick={() => {
                          deleteDevice(row.DeviceId);
                        }}
                      >
                        {row.option}
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      {InActive ? (
        <TableContainer className="shadow-md">
          <Table sx={{ minWidth: 50 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Device ID</TableCell>
                <TableCell align="center">Device</TableCell>
                <TableCell align="center">Customer</TableCell>
                <TableCell align="center">Start Time</TableCell>
                <TableCell align="center">End Time</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Option</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inactive.map((row) => (
                <TableRow
                  key={row.DeviceId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row.DeviceId}
                  </TableCell>
                  <TableCell align="center">{row.DeviceName}</TableCell>
                  <TableCell align="center">{row.Customer}</TableCell>
                  <TableCell align="center">{row.StartTime}</TableCell>
                  <TableCell align="center">{row.EndTime}</TableCell>
                  <TableCell align="center">{row.Status}</TableCell>
                  <TableCell align="center">
                    {row.option === "View" ? (
                      <button
                        className="px-2 py-1 text-white bg-green-500 rounded"
                        onClick={() => {
                          setGlobalState("deviceOptions", true);
                          setGlobalState("deviceStatus", row.Status);
                          setGlobalState("deviceInfoId", row.DeviceId);
                        }}
                      >
                        {row.option}
                      </button>
                    ) : (
                      <button
                        className="px-2 py-1 text-white bg-red-500 rounded"
                        onClick={() => {
                          deleteDevice(row.DeviceId);
                        }}
                      >
                        {row.option}
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
    </div>
  );
}
