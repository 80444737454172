import { Avatar, TextField } from "@mui/material";
import React, { useState } from "react";
import { useGlobalState } from "../../GlobalState/Globalstate";
import DashDeviceChart from "./HomeComponents/DashDeviceChart";

import EditIcon from "@mui/icons-material/Edit";
import { Close } from "@mui/icons-material";
import { getDatabase, ref, set, update } from "firebase/database";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../Firebase/firebaseconfig";
import { toast } from "react-toastify";
export default function Profile() {
  const app = initializeApp(firebaseConfig);

  const dbRef = getDatabase(app);
  const user = useGlobalState("user")[0];
  const AllDevices = useGlobalState("devices")[0];
  const [username, setUserName] = useState(user.name);
  const [phoneNo, setphoneNo] = useState(user.phoneNo);
  const [address, setAddress] = useState(user.address);
  const [edit, setEdit] = useState(false);
  let devices = [];
  let totalLogs = 0;
  let totalLogsStored = 0;
  try {
    devices = Object.values(user.devices);

    devices.map((item) => {
      if (!isNaN(AllDevices[item].TotalLogs)) {
        totalLogs = totalLogs + parseInt(AllDevices[item].TotalLogs);
      }
      //console.log(totalLogs);
    });
  } catch {}
  try {
    devices = Object.values(user.devices);

    devices.map((item) => {
      let logs = Object.keys(AllDevices[item].PreviousLogs);
      totalLogsStored = totalLogsStored + logs.length;
    });
  } catch {}
  return (
    <div className="p-5 md:w-[80%] md:flex">
      <div className="grid md:w-1/2 justify-center">
        <h1 className="text-[25px] text-center">Profile</h1>

        <div className="mt-5 justify-center grid ">
          <Avatar
            src={localStorage.profilePic}
            sx={{ width: "140px", height: "140px" }}
          ></Avatar>
        </div>
        <div className=" mt-5">
          <h1 className="text-[22px] font-[600] text-center">
            {localStorage.userName}
          </h1>
          <h1 className="text-[22px] font-[600] text-center">
            {localStorage.email}
          </h1>
        </div>
        <div className="flex gap-5 mt-5">
          <div className="p-5 border-r-2">
            <h1 className="text-[20px] font-[700] text-center">
              {devices.length}
            </h1>
            <h1 className="text-[18px] text-center">Devices Registered</h1>
          </div>
          <div className="p-5 border-r-2">
            <h1 className="text-[20px] font-[700] text-center">{totalLogs}</h1>
            <h1 className="text-[18px] text-center ">logs Collected</h1>
          </div>
          <div className="p-5 ">
            <h1 className="text-[20px] font-[700] text-center">
              {totalLogsStored}
            </h1>
            <h1 className="text-[18px] text-center">Total Logs Stored</h1>
          </div>
        </div>
        <div>
          <DashDeviceChart></DashDeviceChart>
        </div>
      </div>
      <div className="w-full sm:w-1/2 md:flex pl-5">
        <div className="p-10"></div>
        <div className="w-full sm:w-3/4 ">
          <h1 className="text-[25px] "> Basic Info</h1>
          {edit ? (
            <>
              <div className="mt-5">
                <h1 className="text-[20px]">Username </h1>
                <div className="mt-2">
                  <TextField
                    value={username}
                    onChange={(event) => {
                      setUserName(event.target.value);
                    }}
                    className="w-full"
                  ></TextField>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-[20px]">Phone Number</h1>
                <div className="mt-2">
                  <TextField
                    type="number"
                    value={phoneNo}
                    onChange={(event) => {
                      setphoneNo(event.target.value);
                    }}
                    className="w-full"
                  ></TextField>
                </div>
                <div className="mt-5">
                  <h1 className="text-[20px]">Address </h1>
                  <div className="mt-2">
                    <TextField
                      value={address}
                      onChange={(event) => {
                        setAddress(event.target.value);
                      }}
                      multiline
                      rows={4}
                      className="w-full"
                    ></TextField>
                  </div>
                </div>
                <div className="p-5 gap-5 flex justify-center">
                  <button
                    className="px-8 py-2 rounded-full bg-black font-[600] text-white"
                    onClick={() => {
                      setEdit(false);
                      setAddress(user.address);
                      setphoneNo(user.phoneNo);
                      setUserName(user.name);
                    }}
                  >
                    <Close></Close>
                    Cancel
                  </button>
                  <button
                    className="px-8 py-2 rounded-full bg-blue-500 font-[600] text-white"
                    onClick={() => {
                      update(ref(dbRef, "users/" + localStorage.uid), {
                        name: username,
                        phoneNo: phoneNo,
                        address: address,
                      }).then(() => {
                        localStorage.setItem("userName", username);
                        setEdit(false);
                        toast.success("Info updated successfully ");
                      });
                    }}
                  >
                    <EditIcon
                      sx={{
                        color: "white",
                        fontSize: "18px",
                        marginRight: "2px",
                        marginBottom: "2px",
                      }}
                    ></EditIcon>
                    Save
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mt-5">
                <h1 className="text-[20px]">Username </h1>
                <div className="mt-2">
                  <TextField
                    disabled
                    value={username}
                    onChange={(event) => {
                      setUserName(event.target.value);
                    }}
                    className="w-full"
                  ></TextField>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-[20px]">Phone Number</h1>
                <div className="mt-2">
                  <TextField
                    disabled
                    value={phoneNo}
                    onChange={(event) => {
                      setphoneNo(event.target.value);
                    }}
                    className="w-full"
                  ></TextField>
                </div>
                <div className="mt-5">
                  <h1 className="text-[20px]">Address </h1>
                  <div className="mt-2">
                    <TextField
                      disabled
                      value={address}
                      onChange={(event) => {
                        setAddress(event.target.value);
                      }}
                      multiline
                      rows={4}
                      className="w-full"
                    ></TextField>
                  </div>
                </div>
                <div className="p-5 flex justify-center">
                  <button
                    className="px-8 py-2 rounded-full bg-blue-500 font-[600] text-white"
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    <EditIcon
                      sx={{
                        color: "white",
                        fontSize: "18px",
                        marginRight: "2px",
                        marginBottom: "2px",
                      }}
                    ></EditIcon>
                    Edit
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
