import React from 'react'

export   const firebaseConfig = {
  apiKey: "AIzaSyDum1v3Qaqx526AuJU2iLTDXretmHp_uFw",
  authDomain: "eonroots-cb89d.firebaseapp.com",
  databaseURL: "https://eonroots-cb89d-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "eonroots-cb89d",
  storageBucket: "eonroots-cb89d.appspot.com",
  messagingSenderId: "699350517914",
  appId: "1:699350517914:web:db0dcf5dd7701706a6b723",
  measurementId: "G-JG0BFK08F7"
  };
