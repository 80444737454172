import React, { useState } from "react";
import { setGlobalState, useGlobalState } from "../GlobalState/Globalstate";
import { Divider } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";

export default function HeartBeatError() {
  function elapsedTime(startTime) {
    if (typeof startTime !== "string" || !startTime.includes(":")) {
      console.error("Invalid startTime format. Expected HH:mm.");
      return 0;
    }

    const currentDate = new Date();
    const [hours, minutes] = startTime.split(":");
    const startDateTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours,
      minutes
    );
    const now = Date.now();
    const elapsedMillis = now - startDateTime.getTime();
    return Math.floor(elapsedMillis / (1000 * 60));
  }

  const heartBeat = useGlobalState("heartBeat")[0];
  const [hiddenIndices, setHiddenIndices] = useState([]);
  //console.log(heartBeat)
  const handleHide = (index) => {
    setHiddenIndices((prev) => [...prev, index]);
  };

  return (
    <div>
      {heartBeat.map((item, index) => {
        if (
          elapsedTime(item.lastUpdated) >= item.heartBeat &&
          !hiddenIndices.includes(index)
        ) {
          return (
            <div
              key={index}
              className="w-full h-full absolute flex justify-center items-center z-[2000] backdrop-blur-md"
            >
              <div className="p-5 bg-[#242121] rounded-xl w-[600px]">
                <div className="flex w-full">
                  <h1 className="text-white text-[28px]">Error:</h1>
                  <div className="flex justify-end w-full text-red-500">
                    <CloseSharp onClick={() => handleHide(index)} />
                  </div>
                </div>
                <Divider />
                <h1 className="text-white text-[30px]">
                  Device ({item.DeviceName}) has not updated any data since{" "}
                  {item.lastUpdated}
                  (Heart Beat set is: {item.heartBeat})
                </h1>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}
