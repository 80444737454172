import {
	Air,
	Close,
	DeviceThermostat,
	FireplaceTwoTone,
	GasMeter,
	GasMeterTwoTone,
	Height,
	Light,
	LineWeight,
	LinkedCamera,
	SmokeFree,
	TenMp,
	Water,
	WaterDrop,
} from '@mui/icons-material';
import { Box, Divider, IconButton, Switch, TextField } from '@mui/material';
import { initializeApp } from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { firebaseConfig } from '../../Firebase/firebaseconfig';
import { getDatabase, push, ref, set, update } from 'firebase/database';
import { setGlobalState, useGlobalState } from '../../GlobalState/Globalstate';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEdit } from '@fortawesome/free-solid-svg-icons';

import dayjs from 'dayjs';

export default function DeviceSettings() {
	const app = initializeApp(firebaseConfig);
	const Alldevices = useGlobalState('devices');
	const deviceid = useGlobalState('deviceInfoId');
	const deviceStatus = useGlobalState('deviceStatus');

	const [startTime, setStartTime] = useState(
		dayjs(Alldevices[0][deviceid[0]].StartTime, 'HH:mm'),
	);
	const [endTime, setEndTime] = useState(
		dayjs(Alldevices[0][deviceid[0]].EndTime, 'HH:mm'),
	);
	const [SecondarystartTime, setSecondaryStartTime] = useState(
		dayjs(Alldevices[0][deviceid[0]].SecondaryStartTime, 'HH:mm'),
	);
	const [SecondaryendTime, setSecondaryEndTime] = useState(
		dayjs(Alldevices[0][deviceid[0]].SecondaryEndTime, 'HH:mm'),
	);
	const [heartBeat, setheartBeat] = useState(
		Alldevices[0][deviceid[0]].heartBeat,
	);
	const [isInitialRender, setIsInitialRender] = useState(true);
	const [TurnedOn, setTurnedOn] = useState(
		Alldevices[0][deviceid[0]].TurnedOn !== undefined
			? Alldevices[0][deviceid[0]].TurnedOn
			: false,
	);
	const [auto, setAuto] = useState(
		Alldevices[0][deviceid[0]].auto !== undefined
			? Alldevices[0][deviceid[0]].auto
			: false,
	);
	const [interval, setInterval] = useState(
		Alldevices[0][deviceid[0]].interval !== undefined
			? Alldevices[0][deviceid[0]].interval
			: '',
	);
	const [Duration, setDuration] = useState(
		Alldevices[0][deviceid[0]].Duration !== undefined
			? Alldevices[0][deviceid[0]].Duration
			: '',
	);
	const [Secondaryinterval, setSecondaryInterval] = useState(
		Alldevices[0][deviceid[0]].Secondaryinterval !== undefined
			? Alldevices[0][deviceid[0]].Secondaryinterval
			: '',
	);
	const [SecondaryDuration, setSecondaryDuration] = useState(
		Alldevices[0][deviceid[0]].SecondaryDuration !== undefined
			? Alldevices[0][deviceid[0]].SecondaryDuration
			: '',
	);

	const updateSecondaryTime = () => {
		set(
			ref(db, 'devices/' + deviceid[0] + '/SecondaryStartTime/'),
			SecondarystartTime,
		).then(async () => {
			set(
				ref(db, 'devices/' + deviceid[0] + '/SecondaryEndTime/'),
				SecondaryendTime,
			).then(async () => {
				toast.success('Secondary Manual time set successfully');
			});
    });
    const dt = `Timer Switch 2  set successfully at ${new Date()}`;
		set(ref(db, `/users/${localStorage.getItem('uid')}/Notifications/111/`), dt.toString());
	};
  const updateTime = () => {
    const endTimeURL = `devices/${deviceid[0]}/EndTime/`
    const startTimeURL = `devices/${deviceid[0]}/StartTime/`
		set(ref(db, startTimeURL), startTime).then(
			async () => {
				set(ref(db, endTimeURL), endTime).then(
					async () => {
						toast.success('Manual time set successfully');
					},
				);
			},
    );
    const dt = `Timer Switch 1  set successfully at ${new Date()}`;
		set(ref(db, `/users/${localStorage.getItem('uid')}/Notifications/111/`), dt.toString());
	};
	const heartBeatUpdate = () => {
		set(ref(db, 'devices/' + deviceid[0] + '/heartBeat/'), heartBeat).then(
			() => {
				toast.success('Heart Beat set successfully');
			},
    );
    const dt = `Heart Beat set successfully at ${new Date()}`;
		set(ref(db, `/users/${localStorage.getItem('uid')}/Notifications/111/`), dt.toString());
	};
	const intervalUpdate = () => {
		set(ref(db, 'devices/' + deviceid[0] + '/interval/'), interval).then(
			async () => {
				set(ref(db, 'devices/' + deviceid[0] + '/Duration/'), Duration).then(
					async () => {
						toast.success('Interval and duration set successfully');
					},
				);
			},
		);
		const dt = `Interval and duration set successfully at ${new Date()}`;
		set(ref(db, `/users/${localStorage.getItem('uid')}/Notifications/111/`), dt.toString());
	};

	const SecondayintervalUpdate = () => {
		set(
			ref(db, 'devices/' + deviceid[0] + '/Secondayinterval/'),
			Secondaryinterval,
		).then(async () => {
			set(
				ref(db, 'devices/' + deviceid[0] + '/SecondayDuration/'),
				SecondaryDuration,
			).then(async () => {
				toast.success('Seconday Interval and duration set successfully');
			});
		});
	};
	const db = getDatabase(app);
	const turnon = () => {
		set(ref(db, 'devices/' + deviceid[0] + '/TurnedOn/'), TurnedOn).then(
			async () => {
				if (TurnedOn) {
					toast.success('Device Turned On Successfully');
				} else {
					toast.success('Device Turned Off Successfully');
				}
			},
		);
	};
	const Auto = () => {
		set(ref(db, 'devices/' + deviceid[0] + '/auto/'), auto).then(async () => {
			if (auto) {
				toast.success('Device Auto mode Turned On Successfully');
			} else {
				toast.success('Device Auto mode Turned Off Successfully');
			}
		});
	};
	useEffect(() => {
		if (Alldevices[0][deviceid[0]].TurnedOn !== undefined) {
			setTurnedOn(Alldevices[0][deviceid[0]].TurnedOn);
		}
	}, []);
	useEffect(() => {
		if (!isInitialRender) {
			turnon();
		}
	}, [TurnedOn]);
	useEffect(() => {
		if (!isInitialRender) {
			Auto();
		}
	}, [auto]);

	useEffect(() => {
		setIsInitialRender(false);
	}, []);

	try {
		return (
			<div className='wrap absolute w-full h-full backdrop-blur-md top-0 z-[2] left-0 grid justify-center items-center'>
				<div className='bg-white p-5 rounded shadow-lg'>
					<div className='md:flex w-full'>
						<div className='w-[90%]'>
							<h1 className=' text-[30px]'>Device Settings ({deviceid[0]})</h1>
						</div>
						<IconButton
							onClick={() => {
								setGlobalState('deviceSettings', false);
							}}>
							<Close sx={{ color: 'red' }}></Close>
						</IconButton>
					</div>
					<Divider></Divider>
					<div className='p-5 md:flex'>
						<div className='p-5 pl-0'>
							<h1 className=' text-[20px]'>Device Id : {deviceid[0]}</h1>
							<div className='md:flex gap-2'>
								<h1 className=' text-[20px] mt-2'>
									Device Name :{' '}
									{Alldevices[0][deviceid[0]].DeviceName
										? Alldevices[0][deviceid[0]].DeviceName
										: '-'}
								</h1>
								<button
									className='text-white bg-blue-500 px-2 py1 rounded-full   gap-3'
									onClick={() => {
										setGlobalState('nameEdit', true);
									}}>
									<FontAwesomeIcon
										icon={faEdit}
										className='mr-2'></FontAwesomeIcon>
									Edit
								</button>
							</div>
							<h1 className=' text-[20px] mt-2'>
								Device Status : {deviceStatus[0]}
							</h1>
							<div className='md:flex mt-2'>
								<h1 className=' text-[20px]'>Device Off/On : </h1>
								<Switch
									checked={TurnedOn}
									onChange={() => {
										setTurnedOn((prevTurnedOn) => !prevTurnedOn);
									}}
								/>
							</div>
							<div className='md:flex mt-2'>
								<h1 className=' text-[20px]'>Auto Mode Off/On : </h1>
								<Switch
									checked={auto}
									onChange={() => {
										setAuto((prevTurnedOn) => !prevTurnedOn);
									}}
								/>
							</div>
							<div className='md:flex mt-2'>
								<h1 className=' text-[20px]'>Edit Parameters: </h1>
								<button
									className='text-white ml-5 bg-blue-500 px-2 py1 rounded-full   gap-3'
									onClick={() => {
										setGlobalState('editParams', true);
									}}>
									<FontAwesomeIcon
										icon={faEdit}
										className='mr-2'></FontAwesomeIcon>
									Edit
								</button>
							</div>
						</div>
						<div className='p-5 md:border-l-2 border-t-2 md:border-t-0'>
							<h1 className=' text-[20px]'>Timer Switch -1 : </h1>

							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['TimePicker']}>
									<TimePicker
										label='Start Time'
										value={startTime}
										onChange={(newValue) => {
											const selectedTime = newValue.format('HH:mm');

											setStartTime(selectedTime);
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>

							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['TimePicker']}>
									<TimePicker
										label='End Time'
										value={endTime}
										onChange={(newValue) => {
											const selectedTime = newValue.format('HH:mm');
											setEndTime(selectedTime);
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>

							<div className='md:flex justify-center'>
								<button
									className='bg-blue-500 px-2 py-1 rounded-full text-white mt-2 '
									onClick={() => {
										try {
											//console.log(startTime);
											//console.log(endTime);
											updateTime();
										} catch (err) {
											updateTime();
										}
									}}>
									<FontAwesomeIcon
										icon={faClock}
										className='mr-2'></FontAwesomeIcon>
									Save Time
								</button>
							</div>
							<h1 className=' text-[20px]'>Timer Switch -2 </h1>

							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['TimePicker']}>
									<TimePicker
										label='Start Time'
										value={SecondarystartTime}
										onChange={(newValue) => {
											const selectedTime = newValue.format('HH:mm');

											setSecondaryStartTime(selectedTime);
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>

							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['TimePicker']}>
									<TimePicker
										label='End Time'
										value={SecondaryendTime}
										onChange={(newValue) => {
											const selectedTime = newValue.format('HH:mm');
											setSecondaryEndTime(selectedTime);
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>

							<div className='md:flex justify-center'>
								<button
									className='bg-blue-500 px-2 py-1 rounded-full text-white mt-2 '
									onClick={() => {
										try {
											//console.log(startTime);
											//console.log(endTime);
											updateSecondaryTime();
										} catch (err) {
											updateSecondaryTime();
										}
									}}>
									<FontAwesomeIcon
										icon={faClock}
										className='mr-2'></FontAwesomeIcon>
									Save Time
								</button>
							</div>
						</div>

						<div className='p-5 md:border-l-2 border-t-2 md:border-t-0'>
							<h1 className=' text-[20px]'>Interval Switch : </h1>
							<div className='mt-2'>
								<TextField
									label='Interval'
									className=' '
									type='number'
									value={interval}
									onChange={(event) => {
										setInterval(event.target.value);
									}}></TextField>
							</div>
							<div className='mt-2'>
								<TextField
									label='Duration'
									className=' '
									type='number'
									value={Duration}
									onChange={(event) => {
										setDuration(event.target.value);
									}}></TextField>
							</div>
							<div className='md:flex justify-center'>
								<button
									className='bg-blue-500 px-2 py-1 rounded-full text-white mt-2 '
									onClick={() => {
										try {
											intervalUpdate();
										} catch (err) {
											toast.error(err);
										}
									}}>
									<FontAwesomeIcon
										icon={faClock}
										className='mr-2'></FontAwesomeIcon>
									Save
								</button>
							</div>
							<h1 className=' text-[20px] mt-5'>Heart Beat: </h1>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['TimePicker']}>
									<TextField
										label='Enter in Minutes'
										type='number'
										value={heartBeat}
										onChange={(event) => {
											setheartBeat(event.target.value);
										}}></TextField>
								</DemoContainer>
							</LocalizationProvider>

							<div className='md:flex justify-center'>
								<button
									className='bg-blue-500 px-2 py-1 rounded-full text-white mt-2 '
									onClick={() => {
										try {
											//console.log(heartBeat);

											heartBeatUpdate();
										} catch (err) {
											heartBeatUpdate();
										}
									}}>
									<FontAwesomeIcon
										icon={faClock}
										className='mr-2'></FontAwesomeIcon>
									Save HeartBeat
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		toast.error('Error! Device Doesnt exist', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'colored',
		});
	}
}
