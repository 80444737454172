import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState } = createGlobalState({
  user: {},
  devices: {},
  addDevice: false,
  deviceInfo: false,
  deviceOptions: false,
  deviceInfoId: "",
  showDevices: false,
  showMain: true,
  activeDevices: 0,
  inActiveDevices: 0,
  activeDevicesId: [],
  deviceStatus: "",
  deviceSettings: false,
  inActiveDevicesId: [],
  nameEdit: false,
  previousLogs: false,
  previousLogData: false,
  previousLogId: "",
  showNotification: false,
  showProfile: false,
  showSideBar: false,
  editParams: false,
  heartBeat:[],
});

export { useGlobalState, setGlobalState };
