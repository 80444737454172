import {
  Air,
  Close,
  DeviceThermostat,
  FireplaceTwoTone,
  GasMeter,
  GasMeterTwoTone,
  Height,
  Light,
  LineWeight,
  LinkedCamera,
  SmokeFree,
  TenMp,
  Water,
  WaterDrop,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Switch, TextField } from "@mui/material";
import { initializeApp } from "firebase/app";
import React, { useEffect, useState } from "react";
import { firebaseConfig } from "../../Firebase/firebaseconfig";
import { child, getDatabase, push, ref, remove, set, update } from "firebase/database";
import { setGlobalState, useGlobalState } from "../../GlobalState/Globalstate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";


export default function PreviousLogsSelector() {
  const app = initializeApp(firebaseConfig);
  const Alldevices = useGlobalState("devices");
  const deviceid = useGlobalState("deviceInfoId");
  const deviceStatus = useGlobalState("deviceStatus");


  const dbRef = ref(getDatabase(app));
  function createData(
    LogId: String,
    LogDate:String,
    LogTime:String,
    FirstOption:String,
    SecondOption:String,
  ) {
    return {
        LogId,
        LogDate,
        LogTime,
        FirstOption,
        SecondOption,
    };
}
let previousLogs=[]
try{
let data=Object.values(Alldevices[0][deviceid[0]].PreviousLogs)
data.map((item)=>{
    previousLogs.push(item)
})

}
catch(err){
previousLogs.push(0)
}
const view=(index)=>{
    let keys=Object.keys(Alldevices[0][deviceid[0]].PreviousLogs)


setGlobalState("previousLogId",keys.reverse()[index])
setGlobalState("previousLogData",true)
setGlobalState("previousLogs",false)
}
const remover=(index)=>{
    let keys=Object.keys(Alldevices[0][deviceid[0]].PreviousLogs)
    remove(child(dbRef,`devices/${deviceid[0]}/PreviousLogs/${keys.reverse()[index]}`)).then(()=>{
        toast.success("Log Deleted Successfully")
    })

}
 
  const db = getDatabase(app);

  try {
    return (
      <div className="absolute w-full h-full backdrop-blur-md top-0 z-[2] left-0 grid justify-center items-center">
        <div className="bg-white p-5 rounded shadow-lg">
          <div className="flex w-full">
            <div className="w-[90%]">
              <h1 className=" text-[30px]">Previous Logs ({deviceid[0]})</h1>
            </div>
            <IconButton
              onClick={() => {
                setGlobalState("previousLogs", false);
              }}
            >
              <Close sx={{ color: "red" }}></Close>
            </IconButton>
          </div>
          <Divider></Divider>
          <div className="flex h-[600px]">
          <TableContainer className="">
          <Table sx={{ minWidth: 50 }} aria-label="simple table">
            <TableHead>
              <TableRow>
              
                <TableCell align="center">Log Date</TableCell>
                <TableCell align="center">Log Time</TableCell>
                <TableCell align="center">Option</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {previousLogs.reverse().map((row,index)=>(
                  <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row.CurrentDate}
                  </TableCell>
                  <TableCell align="center">{row.CurrentTIme}</TableCell>
                  <TableCell align="center">
<div className="flex justify-center gap-2">
    <button className="px-2 py-1 bg-blue-500 text-white rounded" onClick={()=>{
      view(index)  
    }}><FontAwesomeIcon icon={faEye}> </FontAwesomeIcon> View </button>
    <button className="px-2 py-1 bg-red-500 text-white rounded" onClick={()=>{
    remover(index)

    }}><FontAwesomeIcon icon={faTrash}> </FontAwesomeIcon> Delete </button>
</div>
                  </TableCell>
                
                  </TableRow>
            )
               
        
  
            )}
                </TableBody>
                </Table>
                </TableContainer>
           </div>
        </div>
      </div>
    );
  } catch (err) {
    toast.error("Error! Device Doesnt exist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
}
