import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import DevicesIcon from "@mui/icons-material/Devices";
import { Box, colors } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import React from "react";
import { setGlobalState, useGlobalState } from "../../../GlobalState/Globalstate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function DashNotification() {
  const user = useGlobalState("user")[0];
  let Notifications;
  try {
    Notifications = Object.values(user.Notifications);
  } catch (err) {
    Notifications = ['No Notifications Found'];
  }
  console.log(user.Notifications);

  return (
    <div className="w-full p-2 sm:p-5 pl-0 w-1/2 ">
      <div className="bg-white shadow-md p-5 rounded  ">
        <div className="flex w-full">
          <div className="w-full sm:w-1/2">
            <h1 className="text-[20px] font-[600] text-[#1385D8] ">
              Notifications <NotificationsActiveIcon></NotificationsActiveIcon>
            </h1>
          </div>
          <div className="w-1/3 sm:flex justify-end w-1/2">
            <button className="px-2 py-1 bg-[#1385D8] rounded text-white " onClick={()=>{
              setGlobalState("showNotification",true)
              setGlobalState("showMain",false)
            }}>
              View all
            </button>
          </div>
        </div>
        <div className="h-[220px] mt-5 w-full overflow-auto">

        {Notifications.reverse().map((item) => {
          return (
            <div>
            {(item.includes("issue"))?( <div className="p-2  text-[20px] mt-5 shadow-sm flex items-center gap-5">
            <NotificationsActiveIcon
              sx={{ color: "red" }}
            ></NotificationsActiveIcon>{" "}
            {item}
          </div>):( <div className="p-2  text-[20px] mt-5 shadow-sm flex items-center gap-5">
          <NotificationsActiveIcon
            sx={{ color: "#1385D8" }}
          ></NotificationsActiveIcon>{" "}
          {item}
        </div>)}
           </div>
          );
        })}
        </div>
      </div>
    </div>
  );
}
