import React from "react";
import { setGlobalState, useGlobalState } from "../../GlobalState/Globalstate";
import { DevicesOther } from "@mui/icons-material";
import DeviceInfo from "./DeviceInfo";
import DeviceList from "./DeviceComponents/DeviceList";
import DeviceSearch from "./DeviceComponents/DeviceSearch";
import DeviceOptions from "./DeviceOptions";
import DeviceSettings from "./DeviceSettings";
import DeviceName from "../DeviceName";
import PreviousLogsSelector from "./PreviousLogsSelector";
import PreviousLogData from "./PreviousLogData";
import EditParams from "../EditParams";

export default function Devices() {
  const Alldevices = useGlobalState("devices");
  const userInfo = useGlobalState("user");
  const deviceinfo = useGlobalState("deviceInfo");
  const deviceOptions = useGlobalState("deviceOptions");
  const deviceSettings = useGlobalState("deviceSettings");
  const nameEdit = useGlobalState("nameEdit");
  const previousLogs = useGlobalState("previousLogs");
  const previousLogData = useGlobalState("previousLogData");
  const editParams = useGlobalState("editParams");
  let userDevices;
  try {
    userDevices = Object.values(userInfo[0].devices);
  } catch (err) {
    userDevices = [];
  }
  try {
    return (
      <div className="p-5 w-full rounded sm:w-[80%] rounded  ">
        {deviceinfo[0] ? <DeviceInfo></DeviceInfo> : <></>}
        {deviceOptions[0] ? <DeviceOptions></DeviceOptions> : <></>}
        {nameEdit[0] ? <DeviceName></DeviceName> : <></>}
        {deviceSettings[0] ? <DeviceSettings></DeviceSettings> : <></>}
        {previousLogs[0] ? (
          <PreviousLogsSelector></PreviousLogsSelector>
        ) : (
          <></>
        )}
        {previousLogData[0] ? <PreviousLogData></PreviousLogData> : <></>}
        {editParams[0] ? <EditParams></EditParams> : <></>}
        <DeviceSearch></DeviceSearch>
        <DeviceList></DeviceList>
      </div>
    );
  } catch (err) {
    return (
      <div className="p-5 bg-gray-900 rounded shadow-md text-white">
        <h1 className="text-[30px]">Devices</h1>
        <p>Add Devices </p>
      </div>
    );
  }
}
