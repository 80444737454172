import { child, getDatabase, onValue, ref } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../Firebase/firebaseconfig';
import { setGlobalState } from '../GlobalState/Globalstate';

export default function FetchData() {
  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);
  const dbRef = ref(db, 'devices'); 

  const handleDataChange = (data) => {
    //console.log('Data changed:', data);
    setGlobalState('devices', data);
  };

  
  const unsubscribe = onValue(dbRef, (snapshot) => {
    const data = snapshot.val();
    if (data) {
      handleDataChange(data);
    } else {
      setGlobalState('devices', {});
    }
  });

  return unsubscribe; 
}
