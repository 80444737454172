import React, { useState } from 'react'
import {
  Divider,
  IconButton,
  TextField,
  Button,
  Checkbox,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import { setGlobalState, useGlobalState } from '../GlobalState/Globalstate'
import { CheckBox, Close } from '@mui/icons-material'
import { getDatabase, push, ref, set, update } from 'firebase/database'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from '../Firebase/firebaseconfig'
import { toast } from 'react-toastify'

export default function EditParams() {
  const Alldevices = useGlobalState("devices")
  const deviceid = useGlobalState("deviceInfoId")
  const app = initializeApp(firebaseConfig)
  const db = getDatabase(app)
  
  let fields={
    sensor1value:"sensor1value",
    sensor2value:"sensor2value",
    sensor3value:"sensor3value",
    sensor4value:"sensor4value",
    sensor5value:"sensor5value",
    sensor6value:"sensor6value",
   
  }

  const [deviceParams, setDeviceParams] = useState(
    (Alldevices[0][deviceid[0]].params !== undefined) 
      ? (Alldevices[0][deviceid[0]].params)
      : Object.keys(fields).map(key => ({show:true, field: key, value: fields[key] }))
  )

  const handleFieldChange = (index, event) => {
    const newDeviceParams = [...deviceParams]
    newDeviceParams[index].field = event.target.value
    setDeviceParams(newDeviceParams)
  }
  const handleShowChange = (index, event) => {
    const newDeviceParams = [...deviceParams];
    newDeviceParams[index].show = event.target.checked;
    setDeviceParams(newDeviceParams);
}
  const handleSelectChange = (index, event) => {
    const newDeviceParams = [...deviceParams]
    newDeviceParams[index].value = event.target.value
    setDeviceParams(newDeviceParams)
  }

  const saveParams = () => {
    //console.log(deviceParams);
    set(ref(db, "/devices/"+deviceid[0]+"/params"), 
    deviceParams
)
    .then(async () => {
        toast.success("Parameters saved!")
        setGlobalState("editParams",false)
    })
 
  }

  return (
    <div className='fixed w-full h-full z-10 backdrop-blur-md top-0 left-0 grid justify-center items-center'>
      <div className='bg-white p-5 rounded shadow-lg'>
        <div className='flex w-full'>
          <div>
            <h1 className=' text-[30px]'>Edit Params ({deviceid[0]})</h1>
          </div>
          <IconButton onClick={() => {
            setGlobalState("editParams", false)
          }}>
            <Close sx={{ color: "red" }}></Close>
          </IconButton>
        </div>
        <Divider></Divider>
        <div className='p-5 flex gap-5 over'>
          <div className='w-full overflow-auto h-[600px]'>
            {(Object.keys(deviceParams).length !== 0) ? (
              <TableContainer component={Paper}>
                <Table className='w-full' aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Show</TableCell>
                      <TableCell align="center">Fields</TableCell>
                      <TableCell align="center">Values</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deviceParams.map((param, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Checkbox checked={param.show}
                          onChange={(event)=>{
                            handleShowChange(index,event)
                          }}
                          ></Checkbox>
                        </TableCell>
                        <TableCell align="center">
                          <TextField 
                            label={`field ${index + 1}`} 
                            value={param.field}
                            onChange={(event) => handleFieldChange(index, event)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Select
                            value={param.value}
                            onChange={(event) => handleSelectChange(index, event)}
                          >
                            {Object.values(fields).map((field, i) => (
                              <MenuItem key={i} value={field}>{field}</MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              "No Parameters"
            )}
          </div>
        </div>
        <div className='p-5 flex justify-end'>
          <Button variant="contained" color="primary" onClick={saveParams}>Save</Button>
        </div>
      </div>
    </div>
  )
}
