import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, TextField } from "@mui/material";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@mui/material/Autocomplete";
import {
  setGlobalState,
  useGlobalState,
} from "../../../GlobalState/Globalstate";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import FetchData from "../../../Fetch/FetchData";
import UserData from "../../../Fetch/UserData";

const DashHeader = () => {
  const [devices, setDevices] = useState([]);
  const [globalState] = useGlobalState("user");
  const [id, setId] = useState("");
  const [value, setValue] = useState("");
  const showSideBar = useGlobalState("showSideBar");
  const showDash = useGlobalState("showMain");
  // const showDash = false;

  useEffect(() => {
    if (globalState.devices) {
      const devices = Object.values(globalState.devices);
      setDevices(devices);
    }
  }, [globalState.devices]);

  return (
    <div
      className={`relative ${
        !showDash[0] ? "w-full px-6 py-2 flex justify-between" : ""
      }`}
    >
      <div
        className={`w-full items-center flex gap-4  ${
          !showDash[0] ? "justify-between" : ""
        } sm:gap-0`}
      >
        <div
          onClick={() => setGlobalState("showSideBar", !showSideBar[0])}
          className="flex sm:hidden"
        >
          <MenuOutlinedIcon />
        </div>
        {showDash[0] && (
          <div className="flex w-full sm:w-full">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                //console.log(newInputValue);
                devices.map((item) => {
                  if (newInputValue === item) {
                    setGlobalState("deviceInfo", true);
                    setGlobalState("deviceInfoId", item);
                  }
                });
              }}
              options={devices}
              className="bg-gray-200 sm:w-3/5 w-full rounded-l-full"
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& fieldset": { border: "none" },
                    "& legend": { display: "none" },
                  }}
                  className="w-full"
                  label="Search"
                />
              )}
            />

            <button className="px-3 py-2 bg-gray-900 md:px-5 py-2 rounded-r-full">
              <FontAwesomeIcon icon={faSearch} className="text-white" />
            </button>
          </div>
        )}

        <div className="relative sm:absolute right-5">
          <div className="flex">
            <div className="mx-3 flex-column gap-1 items-start sm:flex gap-2 items-center">
              <Avatar src={localStorage.profilePic}></Avatar>
              <div className="hidden sm:block">
                <h1 className="text=[12px] font-[400] sm:text-[18px] font-[600]">
                  {localStorage.userName}
                </h1>
                <h1 className="text=[12px] font-[400] sm:text-[14px] font-[500]">
                  {localStorage.email}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashHeader;
