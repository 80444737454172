import {
  NotificationAdd,
  Notifications,
  NotificationsActive,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useGlobalState } from "../../GlobalState/Globalstate";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../Firebase/firebaseconfig";
import { child, getDatabase, ref, remove } from "firebase/database";
import { toast } from "react-toastify";

export default function NotficationsPage() {
  const app = initializeApp(firebaseConfig);

  const dbRef = ref(getDatabase(app));
  const DeleteAll = () => {
    remove(child(dbRef, `users/${localStorage.uid}/Notifications`)).then(() => {
      toast.success("Notifications Deleted successfully");
    });
  };
  const user = useGlobalState("user")[0];
  let Notification;
  try {
    Notification = Object.values(user.Notifications);
  } catch (err) {
    Notification = ["No Notifications Found"];
  }
  const [all, setAll] = useState(true);
  const [error, setErr] = useState(false);
  const [success, setSuc] = useState(false);
  const AllNoti = () =>
    Notification.reverse().map((item) => {
      return (
        <div>
          {item.includes("issue") ? (
            <div className="p-2  text-[20px] mt-5 shadow-sm flex items-center gap-5">
              <NotificationsActive sx={{ color: "red" }}></NotificationsActive>{" "}
              {item}
            </div>
          ) : (
            <div className="p-2  text-[20px] mt-5 shadow-sm flex items-center gap-5">
              <NotificationsActive
                sx={{ color: "#1385D8" }}
              ></NotificationsActive>{" "}
              {item}
            </div>
          )}
        </div>
      );
    });
  const Allerr = () =>
    Notification.reverse().map((item) => {
      if (item.includes("issue")) {
        return (
          <div>
            <div className="p-2  text-[20px] mt-5 shadow-sm flex items-center gap-5">
              <NotificationsActive sx={{ color: "red" }}></NotificationsActive>{" "}
              {item}
            </div>
          </div>
        );
      }
    });
  const AllSuc = () =>
    Notification.reverse().map((item) => {
      return (
        <div>
          {item.includes("issue") ? (
            <></>
          ) : (
            <div className="p-2  text-[20px] mt-5 shadow-sm flex items-center gap-5">
              <NotificationsActive
                sx={{ color: "#1385D8" }}
              ></NotificationsActive>{" "}
              {item}
            </div>
          )}
        </div>
      );
    });
  return (
    <div className="p-5 sm:w-[80%] rounded ">
      <div className="flex">
        <div className=" w-full overflow-auto sm:w-1/2">
          <h1 className="text-[20px]">
            {" "}
            Notifications <Notifications></Notifications>
          </h1>
        </div>
        <div className="flex justify-end  w-full">
          <button
            className="bg-red-500 text-white px-2 py-2 rounded"
            onClick={DeleteAll}
          >
            Delete All
          </button>
        </div>
      </div>
      <div className="p-5 mt-5 pb-0 flex border-b-2 shadow-sm gap-20">
        <div
          className="pb-5 cursor-pointer"
          onClick={() => {
            setAll(true);
            setErr(false);
            setSuc(false);
          }}
        >
          <h1 className="text-[13px] sm:text-[18px]">All Messages</h1>
        </div>

        <div
          className="cursor-pointer"
          onClick={() => {
            setAll(false);
            setErr(true);
            setSuc(false);
          }}
        >
          <h1 className="text-[14px] sm:text-[18px] sm:ml-5"> Error</h1>
        </div>

        <div
          className="cursor-pointer"
          onClick={() => {
            setAll(false);
            setErr(false);
            setSuc(true);
          }}
        >
          <h1 className="text-[14px] sm:text-[18px] sm:ml-5"> Success</h1>
        </div>
      </div>
      <div>
        {all ? <AllNoti></AllNoti> : <></>}
        {error ? <Allerr></Allerr> : <></>}
        {success ? <AllSuc></AllSuc> : <></>}
        {/* {Notification.reverse().map((item) => {
          return (
            <div>
            {(item.includes("issue"))?( <div className="p-2  text-[20px] mt-5 shadow-sm flex items-center gap-5">
            <NotificationsActive
              sx={{ color: "red" }}
            ></NotificationsActive>{" "}
            {item}
          </div>):( <div className="p-2  text-[20px] mt-5 shadow-sm flex items-center gap-5">
          <NotificationsActive
            sx={{ color: "#1385D8" }}
          ></NotificationsActive>{" "}
          {item}
        </div>)}
           </div>
          );
        })} */}
      </div>
    </div>
  );
}
