import React, { useEffect, useState } from "react";
import {
  setGlobalState,
  useGlobalState,
} from "../../../GlobalState/Globalstate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, Avatar, TextField } from "@mui/material";

export default function DeviceSearch() {
  const [devices, setDevices] = useState([]);
  const [globalState] = useGlobalState("user");
  const [id, setId] = useState("");
  const [value, setValue] = useState("");
  useEffect(() => {
    if (globalState.devices) {
      const devices = Object.values(globalState.devices);
      setDevices(devices);
    }
  }, [globalState.devices]);
  return (
    <div className="relative bg-[#F4F4F4] p-5">
      <div className="flex items-center">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            //console.log(newInputValue);
            devices.map((item) => {
              if (newInputValue === item) {
                setGlobalState("deviceInfo", true);
                setGlobalState("deviceInfoId", item);
              }
            });
          }}
          options={devices}
          className="bg-white w-[200px] rounded-l-full"
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                "& fieldset": { border: "none" },
                "& legend": { display: "none" },
              }}
              label="Search"
            />
          )}
        />

        <button className="bg-white px-5 py-4 rounded-r-full">
          <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
        </button>

        <div className=" flex w-full justify-end">
          <div className="flex">
            <div>
              {" "}
              <button
                className="bg-[#5169E5] py-2 px-2 rounded-full text-white"
                onClick={() => {
                  setGlobalState("addDevice", true);
                }}
              >
                Add Devices
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
