import React from "react";
import Chart from "react-apexcharts";
import { useGlobalState } from "../../../GlobalState/Globalstate";
export default function DashDeviceChart() {
  const activeDevicesId = useGlobalState("activeDevicesId");
  const AllDevices=useGlobalState("devices")
let logData=[]
activeDevicesId[0].map((item)=>{
  if(  AllDevices[0][item].TotalLogs!==undefined){
    logData.push(AllDevices[0][item].TotalLogs)
  }
  else{
    logData.push(0)
  }


})
  let options = {
    series: [
      {
        data: logData,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // //console.log(chart, w, e)
          },
        },
      },

      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: activeDevicesId[0],

        labels: {
          style: {
            colors: "#ffffff",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#ffffff",
          },
        },
      },
    },
  };

  return (
    <div className="w-full p-5">
      <div className="bg-[#43475A] p-5 rounded shadow-md">
        <Chart
          options={options.options}
          series={options.series}
          type="bar"
          height={320}
        />
      </div>
    </div>
  );
}
