import React from "react";
import Chart from "react-apexcharts";
import { useGlobalState } from "../../../GlobalState/Globalstate";
export default function DashDevicePie() {
  const activeDevices = useGlobalState("activeDevices");
  const inActiveDevices = useGlobalState("inActiveDevices");
  let options = {
    series: [activeDevices[0], inActiveDevices[0]],

    options: {
      labels: ["Active Devices", "InActive Devices"],
      chart: {
        type: "donut",
      },
      legend: {
        position: "bottom",
        formatter: function (seriesName, opts) {
          return seriesName + ": " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      colors: ["#63CD81", "#ff0000"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div className="p-5 pl-0 bg-white shadow-md w-full items-center flex justify-center sm:w-[30%]">
      <Chart
        options={options.options}
        series={options.series}
        type="donut"
        width={350}
        height={350}
      />
    </div>
  );
}
