import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import DevicesIcon from '@mui/icons-material/Devices';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import {
	setGlobalState,
	useGlobalState,
} from '../../../GlobalState/Globalstate';

export default function DashDevices() {
	const userinfo = useGlobalState('user');
	let Alldevices = useGlobalState('devices')[0];
	let devices;
	let activeDevice = [];
	let inActiveDevices = [];
	//  To find the heartbeat devices
	function elapsedTime(startTime, stateDate) {
		if (typeof startTime !== 'string' || !startTime.includes(':')) {
			console.error('Invalid startTime format. Expected HH:mm.');
			return 0;
		}

		const currentDate = new Date(stateDate);
		const [hours, minutes] = startTime.split(':');
		const startDateTime = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate(),
			hours,
			minutes,
    );
    console.log(startDateTime)
		const now = Date.now();
		const elapsedMillis = now - startDateTime.getTime();
		//console.log(Math.floor(elapsedMillis / (1000 * 60)))
		return Math.floor(elapsedMillis / (1000 * 60));
	}

	const heartBeat = useGlobalState('heartBeat')[0];
	//console.log(heartBeat)
	try {
		Alldevices = Object.keys(Alldevices);
		//console.log(Alldevices);
		devices = Object.values(userinfo[0].devices).length;
		let userDevices = Object.values(userinfo[0].devices);
		heartBeat.map((item) => {
			//console.log(item.lastUpdated)

			if (elapsedTime(item.lastUpdated,item.lastUpdatedDate) >= item.heartBeat) {
				inActiveDevices.push(item.deviceId);
			} else {
				activeDevice.push(item.deviceId);
			}
		});
		setGlobalState('activeDevicesId', activeDevice);
		setGlobalState('inActiveDevicesId', inActiveDevices);
		setGlobalState('activeDevices', activeDevice.length);
		setGlobalState('inActiveDevices', inActiveDevices.length);
	} catch (err) {
		devices = 0;
	}

	return (
		<div className='mt-4 sm:mt-0 p-2 sm:p-5 pl-0 w-full md:w-1/2'>
			<div className='p-2 bg-white shadow-md sm:p-5 rounded'>
				<div className='flex '>
					<h1 className='text-[20px] font-[600] text-[#1385D8]'>Devices</h1>
					<div className='flex justify-end w-full'>
						<button
							className='px-2 py-1 bg-[#1385D8] rounded text-white '
							onClick={() => {
								setGlobalState('addDevice', true);
							}}>
							Add Devices
						</button>
					</div>
				</div>
				<div className='flex'>
					<Box className='p-5 rounded bg--900 flex w-1/2 '>
						<div className='flex items-center gap-3'>
							<div className='hidden sm:flex'>
								<DevicesOtherIcon
									sx={{
										width: '70px',
										height: '70px',
										color: '#5686E1',
									}}></DevicesOtherIcon>
							</div>
							<div className='flex sm:hidden'>
								<DevicesOtherIcon
									sx={{
										width: '30px',
										height: '30px',
										color: '#5686E1',
									}}></DevicesOtherIcon>
							</div>
							<div className>
								<h1 className='text-[18px] text-[#455678] font-[400] sm:text-[25px] sm:font-[700]'>
									{devices}
								</h1>
								<h1 className='text-[12px] sm:text-[18px]'>Total Devices</h1>
							</div>
						</div>
					</Box>
					<Box className='p-5 rounded bg--900 flex w-1/2 '>
						<div className='flex items-center gap-3'>
							<div className='hidden sm:flex'>
								<DevicesOtherIcon
									sx={{
										width: '70px',
										height: '70px',
										color: '#5686E1',
									}}></DevicesOtherIcon>
							</div>
							<div className='flex sm:hidden'>
								<DevicesOtherIcon
									sx={{
										width: '30px',
										height: '30px',
										color: '#5686E1',
									}}></DevicesOtherIcon>
							</div>
							<div className>
								<h1 className='text-[18px] text-[#455678] font-[400] sm:text-[25px] sm:font-[700]'>
									{devices}
								</h1>
								<h1 className='text-[12px] sm:text-[18px]'>New Devices</h1>
							</div>
						</div>
					</Box>
				</div>

				<div className='flex'>
					<Box className='p-5 rounded bg--900 flex w-1/2 '>
						<div className='flex items-center gap-3'>
							<div className='hidden sm:flex'>
								<DevicesIcon
									sx={{
										width: '70px',
										height: '70px',
										color: '#5AF02B',
									}}></DevicesIcon>{' '}
							</div>
							<div className='flex sm:hidden'>
								<DevicesIcon
									sx={{
										width: '30px',
										height: '30px',
										color: '#5AF02B',
									}}></DevicesIcon>{' '}
							</div>
							<div className>
								<h1 className='text-[18px] text-[#5AF02B] font-[400] sm:text-[25px] sm:font-[700]'>
									{activeDevice.length}
								</h1>
								<h1 className='text-[12px] sm:text-[18px]'>Active Devices</h1>
							</div>
						</div>
					</Box>

					<Box className='p-5 rounded bg--900 flex '>
						<div className='flex items-center gap-3'>
							<div className='hidden sm:flex'>
								<DevicesIcon
									sx={{
										width: '70px',
										height: '70px',
										color: '#CE2F2F',
									}}></DevicesIcon>{' '}
							</div>
							<div className='flex sm:hidden'>
								<DevicesIcon
									sx={{
										width: '30px',
										height: '30px',
										color: '#CE2F2F',
									}}></DevicesIcon>{' '}
							</div>

							<div>
								<h1 className='text-[18px] text-[#CE2F2F] font-[400] sm:text-[25px] sm:font-[700]'>
									{inActiveDevices.length}
								</h1>
								<h1 className='text-[12px] sm:text-[18px]'>InActive Devices</h1>
							</div>
						</div>
					</Box>
				</div>
			</div>
		</div>
	);
}
