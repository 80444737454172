import {
  Air,
  Close,
  DeviceThermostat,
  FireplaceTwoTone,
  GasMeter,
  GasMeterTwoTone,
  Height,
  Light,
  LineWeight,
  LinkedCamera,
  SmokeFree,
  TenMp,
  Water,
  WaterDrop,
} from "@mui/icons-material";
import { Box, Divider, IconButton, TextField } from "@mui/material";
import { initializeApp } from "firebase/app";
import React, { useState } from "react";
import { firebaseConfig } from "../../Firebase/firebaseconfig";
import InfoIcon from "@mui/icons-material/Info";
import {
  child,
  getDatabase,
  push,
  ref,
  remove,
  update,
} from "firebase/database";
import { setGlobalState, useGlobalState } from "../../GlobalState/Globalstate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default function DeviceSettingsDeviceOptions() {
  const app = initializeApp(firebaseConfig);
  const deviceid = useGlobalState("deviceInfoId");
  const deviceStatus = useGlobalState("deviceStatus");
  const db = getDatabase(app);
  const dbRef = ref(getDatabase(app));
  const Alldevices = useGlobalState("devices");
  const devices = useGlobalState("user")[0].devices;
  const del = () => {
    for (const key in devices) {
      if (devices[key] === deviceid[0]) {
        remove(child(dbRef, `users/${localStorage.uid}/devices/${key}`)).then(
          () => {
            remove(child(dbRef, `/devices/${deviceid[0]}/OwnerId`)).then(() => {
              toast.success("Device Deleted successfully");
              setGlobalState("deviceOptions", false);
            });
          }
        );
      }
    }
  };
  try {
    return (
      <div className="absolute w-full h-full backdrop-blur-md top-0 z-[2] left-0 sm:left-[60px] grid justify-center items-center">
        <div className="w-full bg-white p-5 rounded shadow-lg sm:min-w-[750px]">
          <div className="flex-column w-full">
            <IconButton
              onClick={() => {
                setGlobalState("deviceOptions", false);
              }}
            >
              <Close sx={{ color: "red" }}></Close>
            </IconButton>
          </div>

          <div className="p-5 flex flex-col">
            <div className="flex flex-col items-start gap-2">
              <h1 className=" text-[20px]">Device Id : {deviceid[0]}</h1>
              <h1 className=" text-[20px]">
                Device Name :{" "}
                {Alldevices[0][deviceid[0]].DeviceName
                  ? Alldevices[0][deviceid[0]].DeviceName
                  : "-"}
              </h1>
              <h1 className=" text-[20px]">
                Device Status : {deviceStatus[0]}
              </h1>
            </div>
            <div
              className="flex flex-col gap-2 items-start mt-5 sm:flex-row mb-10"
              style={{ height: 150, gap: 10 }}
            >
              <div className="ml-16">
                <button
                  className="px-2 py-2 bg-green-500 flex gap-2 font-[700] rounded items-center text-white"
                  onClick={() => {
                    setGlobalState("deviceOptions", false);
                    setGlobalState("deviceInfo", true);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  View Logs
                </button>
              </div>
              <div className="ml-16">
                <button
                  className="px-2 py-2 bg-orange-500 flex gap-2 font-[700] rounded items-center text-white"
                  onClick={() => {
                    setGlobalState("deviceOptions", false);
                    setGlobalState("previousLogs", true);
                  }}
                >
                  <FontAwesomeIcon icon={faGear} />
                  Previous Logs
                </button>
              </div>

              <div className="ml-16">
                <button
                  className="px-2 py-2 bg-blue-500 flex gap-2 font-[700] rounded items-center text-white"
                  onClick={() => {
                    setGlobalState("deviceOptions", false);
                    setGlobalState("deviceSettings", true);
                  }}
                >
                  <FontAwesomeIcon icon={faGear} />
                  Settings
                </button>
              </div>
              <div className="ml-16">
                <button
                  className="px-2 py-2 bg-red-500 flex gap-2 font-[700] rounded items-center text-white"
                  onClick={() => {
                    del();
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  Delete{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    toast.error("Error! Device Doesnt exist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
}
