import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmailIcon from "@mui/icons-material/Email";
import {
  Button,
  Divider,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import React, { useEffect, useState } from "react";
import { CheckBox } from "@mui/icons-material";
import { firebaseConfig } from "../Firebase/firebaseconfig";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  initializeAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { FcGoogle } from "react-icons/fc";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { ToastContainer, toast } from "react-toastify";
import {
  get,
  getDatabase,
  ref,
  set,
  child,
  remove,
  update,
  push,
} from "firebase/database";
import { useNavigate } from "react-router-dom";

import FetchData from "../Fetch/FetchData";
import UserData from "../Fetch/UserData";

import style from "./SignUp.module.css";

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    if (localStorage.uid !== undefined) {
      navigate("/");
    }
  }, []);
  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  }
  const triggerResetEmail = async () => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const db = getDatabase(app);
    await sendPasswordResetEmail(auth, email)
    .then((res)=>{
        toast.success("Reset Password Link has been sent to the email.")
    })
    .catch((error)=>{
        const errorCode = error.code;

        const errorMessage = error.message;
        console.log(errorCode,errorMessage)
        toast.error("Please enter a valid email id.")
    })
   
    
    console.log("Password reset email sent")
  }
  async function signInGoogle() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const db = getDatabase(app);

    var provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((re) => {
        //console.log(re);
        //console.log(re.user.uid);
        update(ref(db, "users/" + re.user.uid), {
          userid: re.user.uid,
          name: re.user.displayName,
          email: re.user.email,
          profilePic: re.user.photoURL,
        })
          .then(async () => {
            //console.log("logged in");

            localStorage.setItem("uid", re.user.uid);
            localStorage.setItem("userName", re.user.displayName);
            localStorage.setItem("email", re.user.email);
            localStorage.setItem("profilePic", re.user.photoURL);
            FetchData();
            UserData();
            navigate("/");
          })
          .catch((err) => {
            //console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function signIn() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();
    const db = getDatabase(app);
    signInWithEmailAndPassword(auth, email, password)
      .then((re) => {
        //console.log(re);
        //console.log(re.user.uid);
        update(ref(db, "users/" + re.user.uid), {
          userid: re.user.uid,
          name: re.user.displayName,
          email: re.user.email,
          profilePic: re.user.photoURL,
        })
          .then(async () => {
            //console.log("logged in");

            localStorage.setItem("uid", re.user.uid);
            localStorage.setItem("userName", re.user.displayName);
            localStorage.setItem("email", re.user.email);
            localStorage.setItem("profilePic", re.user.photoURL);
            FetchData();
            UserData();
            navigate("/");
          })
          .catch((err) => {
            toast.error(err);
          });
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err.message);
      });
  }
  return (
    <div>
      <div className="flex ">
        <div className="w-full sm:w-1/2 grid items-center">
          <div className="pt-0 p-8">
            <div className="flex justify-center items-center mt-5">
              <button
                className="border-2 shadow-lg text-[#001288] border-[#1C5BD6] px-5 flex gap-5 justify-center items-center py-3 rounded-xl w-full"
                onClick={() => {
                  signInGoogle();
                }}
              >
                <FcGoogle></FcGoogle>
                Sign In with Google
              </button>
            </div>
            <Divider className="p-5">OR</Divider>
            <div className="gap-5">
              <TextField
                className="w-full"
                id="outlined-basic"
                label="Email Id"
                variant="outlined"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <div className="mt-5">
                <TextField
                  className="w-full "
                  type="password"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </div>

              <div className="flex justify-center items-center mt-5 w-full">
                <button
                  className="border-2 shadow-lg text-[#001288] border-[#1C5BD6] px-5 flex gap-5 justify-center items-center py-3 rounded-xl w-full"
                  onClick={() => {
                    //console.log("Button Pressed");
                    if (ValidateEmail(email) && password !== "") {
                      signIn();
                    } else if (!ValidateEmail(email)) {
                      toast.error("Enter a valid email id");
                    } else if (password === "") {
                      toast.error("Password Cant be empty");
                    }
                  }}
                >
                  Sign In
                </button>
              </div>
              <div className="text-center mt-5">
                <h1 className="font-[600]">
                 Forgot Password?{" "}
                  <span
                    className="cursor-pointer text-[#001288]"
                    onClick={() => {
                      triggerResetEmail()
                    }}
                  >
                    {" "}
                    Reset Password
                  </span>
                </h1>
              </div>
              <div className="text-center mt-5">
                <h1 className="font-[600]">
                  Dont have an account?{" "}
                  <span
                    className="cursor-pointer text-[#001288]"
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    {" "}
                    Sign Up
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-full hidden sm:flex">
          {" "}
          <Player
            autoplay
            loop
            src="https://lottie.host/2d761256-5294-428a-9bae-48f7854847cc/u3CkHpFdbA.json"
          ></Player>
        </div>
      </div>
    </div>
  );
}
