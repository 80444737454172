import { Air, Close, DeviceThermostat, FireplaceTwoTone, GasMeter, GasMeterTwoTone, Height, Light, LineWeight, LinkedCamera, SmokeFree, TenMp, Water, WaterDrop } from '@mui/icons-material'
import { Box, Divider, IconButton, TextField } from '@mui/material'
import { initializeApp } from 'firebase/app';
import React, { useState } from 'react'
import { firebaseConfig } from '../../Firebase/firebaseconfig';
import { getDatabase, push, ref, update } from 'firebase/database';
import { setGlobalState, useGlobalState } from '../../GlobalState/Globalstate';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import { ClockIcon } from '@mui/x-date-pickers';
export default function PreviousLogData() {
    const app = initializeApp(firebaseConfig);
const deviceid=useGlobalState("deviceInfoId")
		const db = getDatabase(app);
        const Alldevices=useGlobalState("devices")
        const previousLogId=useGlobalState("previousLogId")[0]
        const [param,setParam]=useState((Alldevices[0][deviceid[0]].params!==undefined)?(Alldevices[0][deviceid[0]].params):(undefined))

        try{
  return (

    <div className='absolute w-full h-full backdrop-blur-md top-0 z-[2] left-0 grid justify-center items-center'>
<div className='bg-white p-5 rounded shadow-lg'>
    <div className='flex w-full'>
        <div className='w-[90%]'>
    <h1 className=' text-[30px]'>Previous Log Info ({deviceid[0]})</h1></div>
    <IconButton onClick={()=>{
        setGlobalState("previousLogData",false)
        setGlobalState("previousLogs",true)
    }}><Close sx={{color:"red"}}></Close></IconButton>
    </div>
    <Divider></Divider>
    {(param!==undefined)?(
      <>

<div className='p-5 flex gap-5'>
<Box>
<TableContainer component={Paper}>
     <Table sx={{ minWidth: 450 }} aria-label="simple table">
       <TableHead>
  {param.map(param=>{
    return(
      (
param.show
      )?( <TableRow>
        <TableCell> {param.field}  </TableCell>
        
        <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId][param.value]}</TableCell>
      </TableRow>):(
<></>
      )
     
     
    )
  })}
  <TableRow>
           <TableCell>  <ClockIcon></ClockIcon>  Log Date & Time </TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].CurrentDate}   {Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].CurrentTIme}</TableCell>
           
         </TableRow>
  </TableHead>
  </Table>
  </TableContainer>
  </Box>
</div>

</>
    ):(
      <div className='p-5 flex gap-5'>
      <Box>
      <TableContainer component={Paper}>
     <Table sx={{ minWidth: 450 }} aria-label="simple table">

       <TableHead>
         <TableRow>
           <TableCell> <Air></Air>   Air Quality</TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Air_Quality}</TableCell>
         </TableRow>
         <TableRow>
           <TableCell> <Height></Height>   Alititude</TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Altitude}</TableCell>
         </TableRow>
         <TableRow>
           <TableCell> <GasMeter></GasMeter>   Cng</TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Cng}</TableCell>
         </TableRow>
         <TableRow>
           <TableCell>  <Water></Water>  Humidity</TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Humidity}</TableCell>
         </TableRow>
         <TableRow>
           <TableCell>  <Light></Light>  Ldr</TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Ldr}</TableCell>
         </TableRow>
         <TableRow>
           <TableCell>  <GasMeterTwoTone></GasMeterTwoTone>  LPG</TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Lpg}</TableCell>
           
         </TableRow>
         <TableRow>
           <TableCell>  <LineWeight></LineWeight>  LPG</TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Pressure}</TableCell>
           
         </TableRow>
         <TableRow>
           <TableCell>  <WaterDrop></WaterDrop>  Rain Value</TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Rain_Value}</TableCell>
           
         </TableRow>
         <TableRow>
           <TableCell>  <FireplaceTwoTone></FireplaceTwoTone>  Smoke </TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Smoke}</TableCell>
           
         </TableRow>
         <TableRow>
           <TableCell>  <DeviceThermostat></DeviceThermostat>  Temperature </TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].Temperature}</TableCell>
           
         </TableRow>
         <TableRow>
           <TableCell>  <ClockIcon></ClockIcon>  Log Date & Time </TableCell>
           
           <TableCell align="right">{Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].CurrentDate}   {Alldevices[0][deviceid[0]].PreviousLogs[previousLogId].CurrentTIme}</TableCell>
           
         </TableRow>
        
       </TableHead>
       <TableBody>
      
       </TableBody>
     </Table>
   </TableContainer>
      </Box>
   </div>
    )}
   
</div>

    </div>
  )}
  catch(err){
    toast.error('Error! Device Doesnt exist', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

  }
}
