import { child, get, getDatabase,onValue, ref } from 'firebase/database'
import React from 'react'
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../Firebase/firebaseconfig';
import { setGlobalState } from '../GlobalState/Globalstate';
export default function UserData() {
    const app = initializeApp(firebaseConfig);
    const db = getDatabase(app);
    const dbRef = ref(db, `/users/${localStorage.uid}`); 
  
    const handleDataChange = (data) => {
      //console.log('Data changed:', data);
      setGlobalState('user', data);
    };
  
    
    const unsubscribe = onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        handleDataChange(data);
      } else {
        setGlobalState('user', {});
      }
    });
  
    return unsubscribe; 
}

    