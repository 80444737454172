import { Close } from "@mui/icons-material";
import { Divider, IconButton, TextField } from "@mui/material";
import { initializeApp } from "firebase/app";
import React, { useState } from "react";
import { firebaseConfig } from "../../Firebase/firebaseconfig";
import { getDatabase, push, ref, set, update } from "firebase/database";
import { setGlobalState, useGlobalState } from "../../GlobalState/Globalstate";
import FetchData from "../../Fetch/FetchData";
import UserData from "../../Fetch/UserData";
import { toast } from "react-toastify";

export default function AddDevices() {
  const app = initializeApp(firebaseConfig);
  const [deviceid, setDeviceId] = useState();
  const AllDevices = useGlobalState("devices")[0];
  const db = getDatabase(app);

  return (
    <div className="absolute w-full h-full z-10 backdrop-blur-md top-0 left-0 grid justify-center items-center">
      <div className="bg-white p-5 rounded shadow-lg">
        <div className="flex w-full">
          <div className="w-[90%]">
            <h1 className="text-[20px] md:text-[30px]">Add Devices</h1>
          </div>
          <IconButton
            onClick={() => {
              setGlobalState("addDevice", false);
            }}
          >
            <Close sx={{ color: "red" }}></Close>
          </IconButton>
        </div>
        <Divider></Divider>
        <div className="p-2 flex gap-2 sm:p-5 flex gap-5">
          <TextField
            className="w-full"
            onChange={(event) => {
              setDeviceId(event.target.value);
            }}
            value={deviceid}
            label="Enter Device ID"
          ></TextField>
          <button
            className="bg-orange-500 px-5 py-1 text-white rounded shadow-md"
            onClick={() => {
              try {
                //console.log(   AllDevices[deviceid])
                if (
                  AllDevices[deviceid] === undefined 
                ) {
                  toast.error(
                    "This device does'nt Exist "
                  );
           
                }
                else if (
                  AllDevices[deviceid] !== undefined &&
                  AllDevices[deviceid].OwnerId === undefined
                ) {
                  set(
                    ref(db, "devices/" + deviceid + "/OwnerId/"),
                    localStorage.uid
                  ).then(async () => {
                    push(
                      ref(db, "users/" + localStorage.uid + "/devices/"),
                      deviceid
                    ).then(async () => {
                      FetchData();
                      UserData();
                      setGlobalState("addDevice", false);
                    });
                  });
                } else if (
                  AllDevices[deviceid].OwnerId !== undefined &&
                  AllDevices[deviceid].OwnerId !== localStorage.uid
                ) {
                  toast.error(
                    "This device is already with someother account. "
                  );

                } 
                
             
                
                
                
                
                else {
                  push(
                    ref(db, "users/" + localStorage.uid + "/devices/"),
                    deviceid
                  ).then(async () => {
                    FetchData();
                    UserData();
                    setGlobalState("addDevice", false);
                  });
                }
              } catch (err) {
                push(
                  ref(db, "users/" + localStorage.uid + "/devices/"),
                  deviceid
                ).then(async () => {
                  FetchData();
                  UserData();
                  setGlobalState("addDevice", false);
                });
              }
            }}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
