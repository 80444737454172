import { Divider, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import { setGlobalState, useGlobalState } from '../GlobalState/Globalstate'
import { Close } from '@mui/icons-material'
import { getDatabase, push, ref, set, update } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../Firebase/firebaseconfig';
import { toast } from 'react-toastify';
export default function DeviceName() {
 
  const Alldevices=useGlobalState("devices")
  const deviceid=useGlobalState("deviceInfoId")
  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);
  const [deviceName,setDeviceName]=useState((Alldevices[0][deviceid[0]].DeviceName!==undefined)?(Alldevices[0][deviceid[0]].DeviceName):(
    ""
  ))
  return (
    <div className='absolute w-full h-full z-10 backdrop-blur-md top-0 left-0 grid justify-center items-center'>
    <div className='bg-white p-5 rounded shadow-lg'>
        <div className='flex w-full'>
            <div className='w-[90%]'>
        <h1 className=' text-[30px]'>Edit Name ({deviceid[0]})</h1></div>
        <IconButton onClick={()=>{
            setGlobalState("nameEdit",false)
        }}><Close sx={{color:"red"}}></Close></IconButton>
        </div>
        <Divider></Divider>
        <div className='p-5 flex gap-5'>
            <TextField className='w-full' onChange={(event)=>{
    setDeviceName(event.target.value)
            }} value={deviceName}label="Enter Device Name"></TextField>
            <button className='bg-orange-500 px-5 py-1 text-white rounded shadow-md' onClick={()=>{
                 set(ref(db, "/devices/"+deviceid[0]+"/DeviceName"), 
                 deviceName
             )
                 .then(async () => {
                    toast.success("Name edited successfully")
                     setGlobalState("nameEdit",false)
                 })
            }}>Set Name</button>
        </div>
    </div>
    
        </div>
  )
}
